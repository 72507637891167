const React = require('react');
const { PropTypes } = require('prop-types');

const {
	default: CardAccolades,
} = require('@forbes/fbs-components/dist/cjs/components/CardAccolades/CardAccolades');
const {
	default: SectionHeader,
} = require('@forbes/fbs-lists/dist/cjs/components/SectionHeader/SectionHeader');
const {
	default: Caption,
} = require('@forbes/fbs-lists/dist/cjs/components/Caption/Caption');
const {
	default: CarouselPagination,
} = require('@forbes/fbs-components/dist/cjs/components/Carousel/CarouselPagination');

const parseBreakpoints = (breakpoint) => parseInt(breakpoint, 10);
const getBreakpoints = (cardCount) => ({
	spaceBetween: 24,
	breakpoints: {
		[parseBreakpoints('180px')]: {
			slidesPerView: 1,
			slidesPerGroup: 1,
			pagination: {
				enabled: true,
			},
		},
		[parseBreakpoints('481px')]: {
			slidesPerView: cardCount,
			slidesPerGroup: cardCount,
			pagination: {
				enabled: true,
			},
		},
		[parseBreakpoints('769px')]: {
			slidesPerView: cardCount,
			slidesPerGroup: cardCount,
			pagination: {
				enabled: true,
			},
		},
		[parseBreakpoints('1025px')]: {
			slidesPerView: cardCount,
			slidesPerGroup: cardCount,
			spaceBetween: cardCount === 1 ? 0 : 48,
			pagination: {
				enabled: true,
			},
		},
	},
});

/**
 * AccoladesBlock component for SimpleSite
 * @param {object} props component props
 * @param {object[]} props.accolades a list of accolades license cards info to display
 * @returns {JSX.Element} AccoladesBlock component
 */
const AccoladesBlock = ({ accoladesBlockData, isMobile }) => {
	const Container = isMobile ? CarouselPagination : 'div';
	return (
		<>
			<div className="accolades-header__container">
				{accoladesBlockData.promoBlockTitle && (
					<SectionHeader
						title={accoladesBlockData.promoBlockTitle}
						addUnderLine={false}
						className="accolades-block__header"
						tag="h2"
					/>
				)}
				{accoladesBlockData.promoBlockCaption && (
					<Caption
						caption={accoladesBlockData.promoBlockCaption}
						className="accolades-block__caption"
					/>
				)}
			</div>
			<div className="accolades-cards__container">
				<Container
					{...getBreakpoints(accoladesBlockData.licenseBlocks.length)}
					paginationProps={{
						clickable: true,
						horizontalClass: 'pagination',
						bulletActiveClass: 'bulletActive',
						bulletClass: 'bullet',
					}}
					controlProps={{
						classNameNext: 'nextArrow',
						classNamePrev: 'prevArrow',
					}}
					data-testid="accolades-list"
					className={isMobile ? 'accolades-cards__container--mobile' : 'accolades-cards__container--desktop'}
				>
					{accoladesBlockData.licenseBlocks.map((accolade) => (
						<CardAccolades
							key={accolade.label}
							draggable={false}
							image={accolade.image}
							title={accolade.label}
							className="accolades-block__card"
							description={accolade.description}
							href={accolade.href}
							cta="Learn More"
						/>
					))}
				</Container>
			</div>
		</>
	);
};

AccoladesBlock.propTypes = {
	accoladesBlockData: PropTypes.object.isRequired,
	isMobile: PropTypes.bool.isRequired,
};

module.exports = {
	AccoladesBlock,
};
