import React from 'react';
import ReactDOM from 'react-dom';
import { AccoladesBlock } from '../../react-components/AccoladesBlock/AccoladesBlock';

document.addEventListener('DOMContentLoaded', () => {
	const accoladesBlock = document.getElementById('AccoladesBlock');
	const { licenseData, isMobile } = window.forbes['simple-site'];

	if (accoladesBlock) {
		ReactDOM.render(
			<AccoladesBlock
				accoladesBlockData={licenseData}
				isMobile={isMobile}
			/>,
			accoladesBlock,
		);
	}
});
